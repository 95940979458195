import style from "./Biography.module.scss";
import { FunctionComponent } from "react";

import bio1 from "../../assets/bio-1.jpg";
import bio2 from "../../assets/bio-2.jpg";
import { ReactComponent as Logo } from "../../assets/williams-logo.svg";

const Biography: FunctionComponent = () => {
    return (
        <div className={style.wrapper} id="biography">
            <div className={style.container}>
                <div className={style.small}>
                    <h2>Biography</h2>
                    <p>Steven Williams wants you to feel mesmerized with catchy hooks and melodies that will make you want to press play again and again.</p>

                    <img src={bio2} alt="Small portrait photograph of Steven" />
                </div>
                <div className={style.large}>
                    <img src={bio1} alt="Large portrait photograph of Steven" />

                    {/* Logo */}
                    <div className={style.logo}>
                        <Logo />
                    </div>
                </div>
            </div>
            <div className={style.biography}>
                    <p>As a kid, Steven Williams always rushed to the local record shop, in his hometown in Denmark, to
                        spend all his hard-earned cash from sweeping floors and doing small jobs for the mechanic down
                        the road.</p>

                    <p>Some years later he was spending all of his daytime finding the latest and most interesting tracks,
                        and all his nights at the local nightclubs - Steven spend nearly a decade becoming a household
                        name DJ'ing across Denmark. But not until recently did he pursue his lifelong dream of making
                        music himself. He spent endless hours learning everything from playing the piano to producing
                        kicks from scratch. A couple of years later - the first tracks were released.
                        With millions of streams within the first year combined with the support from the likes of David
                        Guetta, Don Diablo and Nicky Romero he had already proved he is destined for greatness.</p>

                    <p>A concert in 2019 at one of the biggest festivals in Europe was the spark that ignited his dream of
                        taking this to the next level. Steven Williams is determined to excite fans and listeners with a
                        touch of pop, a twist of disco, a splash of house and an unstoppable feeling of invincibility and
                        pure exuberance.</p>
                </div>
        </div>
    );
};

export default Biography;