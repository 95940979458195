import Track from "components/Track/Track";
import { FunctionComponent } from "react";
import style from "./App.module.scss";

import Banner from "./components/Banner/Banner";
import Sidemenu from "./components/Sidemenu/Sidemenu";
import Video from "./components/Video/Video";
import Biography from "./components/Biography/Biography";
import Contact from "./components/Contact/Contact";

const App: FunctionComponent = () => {
    return (
        <div className={style.wrapper}>
            <Sidemenu />
            
            <div className={style.content}>
                <Banner />
                <Track />
                <Video />
                <Biography />
                <Contact />
            </div>
        </div>
    );
};

export default App;
