import style from "./Contact.module.scss";
import { FunctionComponent } from "react";

const Contact: FunctionComponent = () => {
    return (
        <div className={style.wrapper} id="contact">
            <div className={style.container}>
                <div className={style.item}>
                    <h2>Contact</h2>
                    <p>Laura Ledet Andreassen</p>
                    <a href="mailto:laura@stevenwilliams.dk">laura@stevenwilliams.dk</a>
                </div>
            </div>
        </div>
    );
};

export default Contact;
