import style from "./Banner.module.scss";
import { FunctionComponent } from "react";
import banner from "../../assets/banner.jpg";

import { ReactComponent as Spotify } from "../../assets/spotify.svg";
import { ReactComponent as Apple } from "../../assets/apple.svg";
import { ReactComponent as YouTube } from "../../assets/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Logo } from "../../assets/williams-logo.svg";

const Banner: FunctionComponent = () => {
    return (
        <div className={style.wrapper} id="top">
            <div className={style.background} style={{ backgroundImage: `url(${banner})` }}></div>

            <div className={style.content}>
                <h1>Click Click</h1>
                <h3>New Release - Out now!</h3>

                <strong>Scroll down</strong>
            </div>

            {/* Apps */}
            <div className={style.apps}>
                <a className={style.app_link} target="_blank" rel="noreferrer" href="https://music.apple.com/us/album/click-click-feat-peaceful-james-djabathehot-single/1613047158">
                    <Apple />
                </a>

                <a className={style.app_link} target="_blank" rel="noreferrer" href="https://open.spotify.com/artist/2uzUAMku4jBt5OBEfBM8H1">
                    <Spotify />
                </a>
            </div>

            {/* Social */}
            <div className={style.social}>
                <a className={style.social_link} href="https://www.youtube.com/channel/UCNUm9R0OiK7Krsmxma-1V6g" rel="noreferrer" target="_blank">
                    <YouTube />
                    <span>YouTube</span>
                </a>
                <a className={style.social_link} href="https://www.instagram.com/steven.williams.music/" rel="noreferrer" target="_blank">
                    <Instagram />
                    <span>Instagram</span>
                </a>
                <a className={style.social_link} href="https://www.facebook.com/steven.williams.music" rel="noreferrer" target="_blank">
                    <Facebook />
                    <span>Facebook</span>
                </a>
            </div>

            {/* Logo */}
            <div className={style.logo}>
                <Logo />
            </div>
        </div>
    );
};

export default Banner;
