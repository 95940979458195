import style from "./Sidemenu.module.scss";
import { FunctionComponent } from "react";
import { ReactComponent as Logo } from "../../assets/w-logo.svg";

const Sidemenu: FunctionComponent = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <a className={style.logo} href="#top">
                    <Logo />
                </a>
                <div className={style.menu}>
                    <a className={style.link} href="#release">Latest Release</a>
                    <a className={style.link} href="#biography">Biography</a>
                    <a className={style.link} href="#contact">Contact</a>
                </div>
            </div>
        </div>
    );
};

export default Sidemenu;
