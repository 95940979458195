import style from "./Video.module.scss";
import { FunctionComponent } from "react";
import ReactPlayer from "react-player/youtube";

import songCover from "../../assets/song-cover.jpg";
import { ReactComponent as YouTube } from "../../assets/youtube.svg";

const Video: FunctionComponent = () => {
    return (
        <div className={style.wrapper}>
            <ReactPlayer
                url="https://www.youtube.com/watch?v=vWXdpt6x83U"
                width="100%"
                height="100%"
                playIcon={<button className={style.button}><YouTube /></button>}
                light={songCover}
                playing={true} />
        </div>
    );
};

export default Video;
