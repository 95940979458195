import style from "./Track.module.scss";
import { FunctionComponent, useCallback, useRef, useState } from "react";
import { WaveSurfer, WaveForm } from "wavesurfer-react";

import albumCover from "../../assets/album-cover.jpg";
import url from "../../assets/click-click.mp3";
import { ReactComponent as Play } from "../../assets/play.svg";
import { ReactComponent as Pause } from "../../assets/pause.svg";
import { ReactComponent as Spotify } from "../../assets/spotify.svg";
import { ReactComponent as Apple } from "../../assets/apple.svg";

const Track: FunctionComponent = () => {
    const wavesurferRef = useRef<any>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleWSMount = useCallback(waveSurfer => {
        wavesurferRef.current = waveSurfer;
        if (wavesurferRef.current) {
            wavesurferRef.current.load(url);
            console.log(wavesurferRef.current);


            wavesurferRef.current.on("ready", () => {
                console.log("WaveSurfer is ready");
            });

            wavesurferRef.current.on("loading", (data: any) => {
                console.log("loading --> ", data);
            });

            if (window) {
                (window as any).surferidze = wavesurferRef.current;
            }
        }
    }, []);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);

        if (wavesurferRef.current) {
            wavesurferRef.current.playPause();
        }
    };

    return (
        <div className={style.wrapper} id="release">
            <div className={style.container}>
                <div className={style.header}>
                    <img src={albumCover} alt="Album Cover of Click Click single" />
                    <div className={style.column}>
                        <span>Latest release</span>
                        <h2>Click Click</h2>
                        <span>By: Steven Williams, Peaceful James &amp; DjabaTheHot</span>
                        <span>Duration: 02:34</span>
                    </div>
                    <div className={style.apps}>
                        <a className={style.app_link} target="_blank" rel="noreferrer" href="https://music.apple.com/us/album/click-click-feat-peaceful-james-djabathehot-single/1613047158">
                            <Apple />
                        </a>

                        <a className={style.app_link} target="_blank" rel="noreferrer" href="https://open.spotify.com/artist/2uzUAMku4jBt5OBEfBM8H1">
                            <Spotify />
                        </a>
                    </div>
                </div>
                <div className={style.inner}>
                    <button className={style.button} onClick={handlePlayPause}>
                        {isPlaying ? (
                            <Pause />
                        ) : null}

                        {!isPlaying ? (
                            <Play />
                        ) : null}
                    </button>
                    <WaveSurfer onMount={handleWSMount}>
                        <WaveForm id="waveform">
                        </WaveForm>
                    </WaveSurfer>
                </div>
            </div>
        </div>
    );
};

export default Track;
